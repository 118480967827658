import { imageAdapter } from '@/adapters/constructor/utils/image';

export default ({ data }) => {
  return {
    phone: imageAdapter(data.phone),
    leftCheck: imageAdapter(data.left_check),
    rightCheck: imageAdapter(data.right_check),
    title: data.title,
    disclaimer: data.disclaimer,
  };
};
